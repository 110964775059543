<template>
  <div>
    <LoadingButton
      v-b-modal.request-new-raffle-modal
      class="group button-container"
      size="large"
      variant="green-outline"
    >
      <span class="button-name group-hover:text-white"> <slot /> </span>
      <svg class="button-image" viewBox="0 0 127 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M111.92 0.0625784L104.36 1.5528C104.338 1.55728 104.319 1.56925 104.306 1.58657C104.292 1.6039 104.285 1.62545 104.286 1.64739C104.287 1.66932 104.296 1.69021 104.311 1.70632C104.326 1.72242 104.346 1.73271 104.367 1.73533L116.998 3.26597C117.209 3.29113 117.421 3.30072 117.633 3.29465C118.013 3.28271 118.39 3.22001 118.753 3.10821L125.588 1.00391C125.609 0.998379 125.627 0.985382 125.639 0.967369C125.651 0.949356 125.656 0.927572 125.653 0.906125C125.65 0.884641 125.64 0.864723 125.624 0.849919C125.609 0.835115 125.588 0.826387 125.567 0.82529L112.779 0.00651572C112.619 -0.00457262 112.459 -0.00457262 112.299 0.00651572C112.172 0.0186955 112.045 0.0374075 111.92 0.0625784V0.0625784Z"
          />
          <path
            d="M118.379 5.52411L118.617 23.4941C118.617 23.5543 118.635 23.6131 118.668 23.6634C118.701 23.7137 118.747 23.7535 118.802 23.7778C118.857 23.8022 118.918 23.8101 118.977 23.8008C119.036 23.7914 119.091 23.765 119.136 23.7249L125.597 17.9426C125.791 17.7689 125.949 17.5582 126.062 17.3228C126.174 17.0874 126.24 16.8319 126.254 16.571L126.997 2.39895C127.002 2.32625 126.989 2.25351 126.959 2.18713C126.929 2.12074 126.883 2.06275 126.826 2.01825C126.768 1.97198 126.7 1.94075 126.627 1.92734C126.554 1.91393 126.479 1.91873 126.408 1.94133L119.319 4.21381C119.044 4.30199 118.805 4.4761 118.636 4.71067C118.467 4.94525 118.377 5.228 118.379 5.5176V5.52411Z"
          />
          <path
            d="M117.189 5.90743C117.187 5.54299 117.052 5.19182 116.811 4.92001C116.569 4.64819 116.237 4.47447 115.876 4.43155L102.847 2.87483C102.702 2.85767 102.555 2.87312 102.417 2.92006C102.279 2.967 102.153 3.04426 102.048 3.14623C101.943 3.2482 101.862 3.37233 101.811 3.50963C101.759 3.64692 101.739 3.79394 101.752 3.94002L102.973 18.3299C103.008 18.7273 103.158 19.1058 103.406 19.4179C103.653 19.73 103.987 19.962 104.365 20.0847L116.258 23.957C116.377 23.9971 116.504 24.008 116.628 23.9889C116.752 23.9698 116.87 23.9211 116.972 23.847C117.073 23.7729 117.156 23.6754 117.212 23.5628C117.269 23.4502 117.298 23.3256 117.297 23.1995L117.189 5.90743ZM110.698 11.2529C110.087 11.2529 109.599 11.9896 109.69 14.1069C109.78 16.2242 109.843 17.4967 109.843 17.4967L107.319 16.8253L106.8 7.75619L109.443 8.17079L109.52 9.75097C109.52 9.75097 110.161 7.92568 112.407 8.53846C112.483 11.163 112.499 11.7757 112.499 11.7757C112.499 11.7757 112.025 11.2555 110.696 11.2555L110.698 11.2529Z"
          />
          <path
            d="M1.90953 4.66623H0.891807C0.655495 4.66692 0.429059 4.76151 0.261961 4.92933C0.0948628 5.09716 0.000684964 5.32458 3.07727e-10 5.56193V18.9909C-2.67149e-06 19.0791 0.0173928 19.1665 0.0511833 19.248C0.0849739 19.3295 0.134491 19.4035 0.19688 19.4657C0.259269 19.5278 0.333295 19.577 0.41469 19.6103C0.496085 19.6436 0.583239 19.6604 0.671127 19.6597H3.29332C3.38088 19.6599 3.46762 19.6427 3.54856 19.6092C3.62951 19.5756 3.70307 19.5264 3.76505 19.4643C3.82702 19.4021 3.87619 19.3284 3.90974 19.2471C3.94329 19.1659 3.96056 19.0788 3.96056 18.9909V12.7327C3.96056 10.8214 4.47072 9.34289 6.22058 8.73533C6.42304 8.66521 6.63105 8.61243 6.84238 8.57757C7.10529 8.53308 7.37151 8.51127 7.63812 8.51238H7.8601C7.93556 8.51255 8.01031 8.49778 8.08007 8.46889C8.14983 8.44001 8.21324 8.39759 8.26665 8.34406C8.32007 8.29053 8.36245 8.22694 8.39137 8.15694C8.42028 8.08694 8.43516 8.0119 8.43516 7.93611V5.14081C8.43531 5.05732 8.4119 4.9755 8.36766 4.90482C8.33076 4.84703 8.28113 4.79853 8.22262 4.76304C8.1641 4.72755 8.09826 4.70603 8.03015 4.70013C7.18897 4.60756 5.21583 4.64276 4.02936 6.73142V5.56193C4.02936 5.32437 3.9354 5.09655 3.76815 4.92857C3.6009 4.7606 3.37407 4.66623 3.13755 4.66623H1.90953Z"
          />
          <path
            d="M18.3385 4.66623V5.01825C18.3385 5.01825 17.2338 4.23598 14.9335 4.23598C12.6333 4.23598 8.66623 6.0339 8.66623 11.7836C8.66623 17.5332 11.6181 19.97 15.3139 19.97C16.9807 19.97 18.4294 19.4967 18.9902 19.2242C19.4614 19.588 19.6068 19.6597 20.766 19.6597H22.251V4.66623H18.3385ZM18.3255 15.4211C17.7647 16.1304 16.7678 16.6949 15.4632 16.6949C13.1084 16.6949 12.4775 14.1304 12.4775 12.0013C12.4775 9.04172 13.7094 7.52021 15.2853 7.52021C17.3325 7.52021 18.3281 9.28553 18.3281 9.28553L18.3255 15.4211Z"
          />
          <path
            d="M30.3292 3.34159V0.267278C29.4569 0.173584 28.5804 0.124846 27.7031 0.121254C26.3271 0.121254 24.0619 0.704044 24.0619 4.50587V19.6597H27.9199V8.27249C27.9199 8.27249 30.3292 8.78097 30.3292 5.48892H27.9017V4.23338C27.9017 3.85137 28.1471 3.34159 28.8805 3.34159H30.3292Z"
          />
          <path
            d="M37.3572 3.34159V0.267278C36.4849 0.173584 35.6084 0.124846 34.7311 0.121254C33.3538 0.121254 31.0964 0.704044 31.0964 4.50587V19.6597H34.9544V8.27249C34.9544 8.27249 37.3637 8.78097 37.3637 5.48892H34.9362V4.23338C34.9362 3.85137 35.1816 3.34159 35.915 3.34159H37.3572Z"
          />
          <path
            d="M42.2303 19.6597H38.3542V0.200783H40.5376C40.9852 0.200783 41.4144 0.379354 41.7309 0.697212C42.0473 1.01507 42.2251 1.44618 42.2251 1.8957L42.2303 19.6597Z"
          />
          <path
            d="M49.6205 4.25163C44.4034 4.25163 43.0754 8.90873 43.0754 12.5958C43.0754 15.6036 44.7176 19.97 49.7893 19.97C54.4508 19.97 56.4551 16.9126 56.6693 15.0456H53.9913C53.4604 15.0456 52.9775 15.0209 52.2765 15.8696C51.8871 16.3559 51.1173 16.6949 49.8854 16.6949C48.6534 16.6949 47.084 15.9192 47.084 13.1747H56.89C56.89 13.1747 58.1453 4.25163 49.6205 4.25163ZM52.8438 10.601H47.084C47.084 10.601 47.0477 7.52542 49.9645 7.52542C52.8814 7.52542 52.8438 10.601 52.8438 10.601V10.601Z"
          />
          <path
            d="M65.8496 4.25163C63.1923 4.25163 62.085 5.75489 61.5749 6.53194V1.95306C61.5749 1.46897 61.3834 1.00469 61.0426 0.662385C60.7018 0.320076 60.2395 0.127769 59.7575 0.127769H57.7104V13.0352C57.7104 17.1825 59.9328 19.9726 64.8358 19.9726C69.7388 19.9726 71.8871 16.9883 71.8871 12.4524C71.8871 7.91656 69.6894 4.25163 65.8496 4.25163ZM64.7981 16.6949C62.8055 16.6949 61.5736 15.3129 61.5736 12.0013C61.5736 8.6897 63.169 7.67145 64.7981 7.67145C66.4273 7.67145 67.998 8.8605 67.998 12.0065C67.998 15.1525 66.792 16.6949 64.7981 16.6949Z"
          />
          <path
            d="M79.3253 4.25163C75.2687 4.25163 72.6245 6.98044 72.6245 12.1838C72.6245 17.3872 75.5218 19.97 79.3253 19.97C83.1288 19.97 86.426 17.6767 86.426 12.1838C86.426 6.691 83.6 4.25163 79.3253 4.25163ZM79.5252 16.7914C76.7538 16.7914 76.4279 13.7484 76.4279 12.1838C76.4279 10.6193 76.6811 7.52542 79.5252 7.52542C82.4291 7.52542 82.6109 10.97 82.6109 12.1838C82.6109 13.3977 82.2967 16.7914 79.5252 16.7914V16.7914Z"
          />
          <path
            d="M85.0734 4.56584H89.4208L92.0287 9.12647L94.734 4.56584H99.1294L94.3718 11.8918L99.2982 19.6297H94.8067L92.1858 14.9244L89.3961 19.6297H84.832L89.735 11.8918L85.0734 4.56584Z"
          />
        </g>
      </svg>
    </LoadingButton>
    <b-modal
      id="request-new-raffle-modal"
      title="Start a Rafflebox"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      size="lg"
    >
      <b-form @submit.stop.prevent="onSubmit">
        <b-row>
          <b-col>
            <EventNameInput ref="eventNameInput" v-model="event.name" />
          </b-col>
          <b-col>
            <EventLicenseNumberInput ref="eventLicenseNumberInput" v-model="event.licenseNumber" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <EventLicenseUploadInput
              :licenseNumber="event.licenseNumber"
              :organizationId="organization.id"
              @setLicenseUrl="setLicenseUrl"
              v-if="showLicenseUpload"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <EventShortlinkInput ref="eventShortlinkInput" v-model="event.shortlink" />
          </b-col>
        </b-row>
        <b-row>
          <b-col><EventTimeInput ref="eventTimeInput" :province="event.province" /> </b-col>
        </b-row>
        <b-row>
          <b-col>
            <EventCategorySelect ref="eventCategorySelect" v-model="event.category" />
          </b-col>
          <b-col>
            <DrawLocationInput ref="drawLocationInput" v-model="event.drawLocation" />
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col>
            <EventInSupportOfQuestion ref="eventInSupportOfQuestion" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-tabs>
              <b-tab title="About" class="p-2">
                <b-form-group label-for="input-description">
                  <trumbowyg
                    name="input-description"
                    v-model="event.description"
                    v-validate="{ min: 3 }"
                    aria-describedby="input-description"
                    data-vv-as="description"
                    class="form-control"
                    :config="config"
                  >
                  </trumbowyg>
                </b-form-group>
              </b-tab>
              <b-tab title="Rules" class="p-2">
                <b-form-group label-for="input-rules-and-regs">
                  <trumbowyg
                    name="input-rules-and-regs"
                    v-model="event.rules"
                    v-validate="{ min: 3 }"
                    aria-describedby="input-rules-and-regs"
                    data-vv-as="rules and regulations"
                    class="form-control"
                    :config="config"
                  >
                  </trumbowyg>
                </b-form-group>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div slot="label">Ticket Packages <span class="text-danger">*</span></div>
            <TicketPackagesForm @add-tickets="addTicketsToTicketPackages" />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <EventCommentsInput ref="eventCommentsInput" v-model="event.comments" />
          </b-col>
        </b-row>
      </b-form>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-btn class="mr-2" @click="cancel()">Cancel</b-btn>
        <b-button v-if="creatingEvent" variant="primary" disabled>
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </b-button>
        <b-btn v-else variant="primary" class="mr-2" @click="ok()">Submit</b-btn>
      </template>
      <Alert v-if="requestRaffleFailed" variant="red" class="mt-4" icon="exclamation"
        >Failed to request raffle: {{ errorMessage }}</Alert
      >
    </b-modal>
  </div>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import CategoryService from '@/lib/category-service';
import EventLicenseNumberInput from '@/components/inputs/EventLicenseNumberInput';
import EventLicenseUploadInput from '@/components/inputs/EventLicenseUploadInput';
import EventServiceV2 from '@/lib/event-service-v2';
import EventNameInput from '@/components/forms/EventNameInput';
import EventTimeInput from '@/components/forms/EventTimeInput';
import EventShortlinkInput from '@/components/forms/EventShortlinkInput';
import EventCategorySelect from '@/components/forms/EventCategorySelect';
import DrawLocationInput from '@/components/forms/DrawLocationInput';
import trumbowygConfig from '@/trumbowygConfig';
import TicketPackagesForm from '@/components/TicketPackagesForm';
import EventInSupportOfQuestion from '@/components/forms/EventInSupportOfQuestion.vue';
import EventCommentsInput from '@/components/forms/EventCommentsInput.vue';
import LoadingButton from '@/components/ui/LoadingButton.vue';
import Alert from '@/components/ui/Alert.vue';

export default {
  props: {
    name: {
      type: String
    }
  },
  components: {
    Alert,
    EventLicenseNumberInput,
    EventLicenseUploadInput,
    EventNameInput,
    EventTimeInput,
    EventShortlinkInput,
    EventCategorySelect,
    DrawLocationInput,
    TicketPackagesForm,
    EventInSupportOfQuestion,
    EventCommentsInput,
    LoadingButton
  },
  data() {
    return {
      modalShow: false,
      requestRaffleFailed: false,
      errorMessage: null,
      organization: {
        shortlink: null
      },
      event: {
        name: null,
        drawLocation: null,
        shortlink: null,
        timeZone: null,
        province: null,
        startDate: null,
        endDate: null,
        drawDate: null,
        category: null,
        allowInVenueSales: false,
        description: null,
        rules: null,
        logoUrl: null,
        comments: null,
        licenseNumber: null,
        licenseUrl: null
      },
      categoryOptions: null,
      creatingEvent: false,
      sessionUser: null,
      config: trumbowygConfig.getConfig(),
      ticketPackages: [
        {
          numTickets: null,
          priceCents: null,
          limit: null,
          series: null
        }
      ],
      licenseFile: null,
      willUseInSupportOf: null,
      isRbAdmin: false,
      isRaffleAdmin: false,
      showLicenseUpload: false,
      licenseName: null,
      isUploading: false
    };
  },
  computed: {
    visibleFields() {
      return this.table.fields.filter((field) => field.visible);
    }
  },
  watch: {
    'event.licenseNumber': function () {
      if (this.event.licenseNumber && this.event.licenseNumber.length >= 5 && this.canUploadLicense()) {
        this.showLicenseUpload = true;
      } else {
        this.showLicenseUpload = false;
      }
    }
  },
  async created() {
    this.sessionUser = await getAuth().sessionUser();
    this.isRbAdmin = this.sessionUser.hasRole(Roles.RB_ADMIN);
    this.isRaffleAdmin = this.sessionUser.hasRole(Roles.RAFFLE_ADMIN);
  },
  async mounted() {
    this.categoryOptions = await CategoryService.listCategories();
  },
  methods: {
    canUploadLicense() {
      return this.isRbAdmin;
    },
    setLicenseUrl(licenseUrl) {
      this.event.licenseUrl = licenseUrl;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    addTicketsToTicketPackages(ticketPackages) {
      this.ticketPackages = ticketPackages;
    },
    async resetForm() {
      this.modalShow = false;
      this.requestRaffleFailed = false;
      this.errorMessage = null;
      this.ticketPackages = [
        {
          numTickets: null,
          priceCents: null,
          limit: null,
          series: null
        }
      ];

      this.event = {
        name: null,
        drawLocation: null,
        shortlink: this.organization.shortlink,
        timeZone: null,
        province: null,
        startDate: null,
        endDate: null,
        drawDate: null,
        category: '50/50',
        allowInVenueSales: false,
        description: null,
        rules: null,
        logoUrl: null,
        comments: null,
        licenseNumber: null,
        licenseUrl: null
      };

      this.willUseInSupportOf = null;

      const organization = await OrganizationServiceV2.retrieveOrganization(this.sessionUser.organizationUuid);

      this.organization.id = organization.id;
      this.event.province = organization.province;
      this.event.shortlink = organization.shortlink;
      this.timeZone = organization.timeZone;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      this.event.startDate = this.$refs.eventTimeInput.startDateTime;
      this.event.endDate = this.$refs.eventTimeInput.endDateTime;
      this.event.drawDate = this.$refs.eventTimeInput.drawDateTime;

      this.willUseInSupportOf = this.$refs.eventInSupportOfQuestion.willUseInSupportOf;

      const formValid = await this.$validator.validateAll();
      const datesValid = this.$refs.eventTimeInput.isValid();
      const shortlinkValid = this.$refs.eventShortlinkInput.isValid();
      const eventNameValid = this.$refs.eventNameInput.isValid();
      const eventCategoryValid = this.$refs.eventCategorySelect.isValid();
      const eventLicenseNumberValid = this.$refs.eventLicenseNumberInput.isValid();
      const drawLocationValid = this.$refs.drawLocationInput.isValid();

      if (
        !formValid ||
        !datesValid ||
        !shortlinkValid ||
        !eventNameValid ||
        !eventCategoryValid ||
        !eventLicenseNumberValid ||
        !drawLocationValid
      ) {
        this.errorMessage =
          'Something is either missing or incorrect. Please scroll up and make the suggested changes.';
        this.requestRaffleFailed = true;
        return;
      } else {
        this.errorMessage = null;
        this.requestRaffleFailed = false;
      }

      this.creatingEvent = true;
      const ticketPackages = this.ticketPackages.map((ticketPackage) => {
        return {
          numTickets: ticketPackage.numTickets,
          priceCents: Number(ticketPackage.priceCents) * 100,
          limit: ticketPackage.limit,
          series: ticketPackage.series
        };
      });

      let commentsWithAppendedData = '';

      // Requestor Information
      commentsWithAppendedData += '## Requestor Information\n';

      commentsWithAppendedData += 'Name: ';
      commentsWithAppendedData += '**' + this.sessionUser.name + '**' + '\n';

      commentsWithAppendedData += 'Email: ';
      commentsWithAppendedData += '**' + this.sessionUser.email + '**' + '\n';

      // Options Selected by Requestor

      commentsWithAppendedData += '---\n';
      commentsWithAppendedData += '## Options Selected\n';

      commentsWithAppendedData += 'Member List Required: ';
      commentsWithAppendedData += this.willUseInSupportOf ? '**Yes**\n' : '**No**\n';

      commentsWithAppendedData += 'Banner Assistance Required: ';
      commentsWithAppendedData += this.event.logoUrl === null ? '**Yes**\n' : '**No**\n';

      // Additional Comments

      if (this.event.comments !== null) {
        commentsWithAppendedData += '---\n';
        commentsWithAppendedData += '## Additional Comments (from Requestor)\n';
        commentsWithAppendedData += this.event.comments;
      }

      const event = {
        name: this.event.name,
        shortlink: this.event.shortlink,
        category: this.event.category,
        province: this.event.province,
        startDate: this.event.startDate ? this.event.startDate : undefined,
        endDate: this.event.endDate,
        drawLocation: this.event.drawLocation,
        drawDate: this.event.drawDate,
        licenseNumber: this.event.licenseNumber,
        allowInVenueSales: this.event.allowInVenueSales,
        description: this.event.description,
        rules: this.event.rules,
        ticketPackages: ticketPackages,
        logoUrl: this.event.logoUrl,
        comments: commentsWithAppendedData
      };

      // For some reason we cannot send this property if it is null or empty
      if (this.event.licenseUrl !== null) {
        event.licenseUrl = this.event.licenseUrl;
      }

      try {
        const eventId = await EventServiceV2.createEvent(event);
        this.showSuccessMessage(eventId);
      } catch (error) {
        this.requestRaffleFailed = error;
        this.creatingEvent = false;
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },

    async showSuccessMessage(eventId) {
      try {
        this.creatingEvent = false;
        this.modalShow = false;
        const message = (
          <Alert variant="green" icon="check">
            Thank you for submitting a new Raffle request build. Our Support team will review your Raffle within 2-3
            business days.
          </Alert>
        );
        await this.$bvModal.msgBoxOk(message, {
          centered: true,
          okVariant: 'success',
          title: 'Raffle Requested Successfully',
          noCloseOnBackdrop: true,
          noCloseOnEsc: true
        });

        this.$router.push({ path: '/raffle', query: { id: eventId } });
      } catch (error) {
        this.errorMessage = error;
      }
    }
  }
};
</script>
<style scoped>
.button-image {
  width: 100px;
  align-self: center;
  margin-bottom: -4px;
  fill: #56b65e;
}
.button-container:hover .button-image {
  fill: #ffffff;
}
.button-name {
  line-height: 1;
  padding-right: 0.5rem;
  color: #2c3e50;
}
</style>
